(function () {
  'use strict';
  
  /*----------------------------------------------------*/
  /*  Header Fixed
  /*----------------------------------------------------*/

  function onFixed(BF) {
      BF.addClass('is-fixed');
      $('.fixed-phone').addClass('col-md-12');   
  }

  function offFixed(BF) {
      BF.removeClass('is-fixed');
      $('.fixed-phone').removeClass('col-md-12');
  }

//     function blockFixed(BF) {
//       var $t = $(".block-fixed");
//       var fixedHeight = $(".wpar-fixed").children(".block-fixed").outerHeight(),
//           fixedTop = $t.offset().top,
//           topScroll = fixedTop + fixedHeight;

//       $(".wpar-fixed").height(fixedHeight);

//       $(window).on('scroll resize', function () {
//           if ($(this).scrollTop() > topScroll) {
//               onFixed($t);
//           } else if ($(this).scrollTop() < 20) {
//               offFixed($t);
//           }
//       });
//   }

//   if ($('*').is('.block-fixed')) {
//       $('.block-fixed').wrapAll('<div class="wpar-fixed"></div>');
//       blockFixed();
//       $(window).on('resize', blockFixed);
//   }

  /*----------------------------------------------------*/
  /*  Header Clone
  /*----------------------------------------------------*/

  $(document).ready(function () {
      var hMain = $('.header:not(.is-clone)'),
          hClone = $('.header.is-clone'),
          hMainHeight = hMain.innerHeight(),
          topHeight = hMain.offset().top + hMainHeight,
          hCloneHeight = hClone.innerHeight(),
          hAll = Number(hMainHeight + hCloneHeight);
      //clone = $('.block-fixed').before($('.block-fixed').clone().addClass("clone"));
      $('.header.is-clone').css({
          "top": "-" + hAll + "px"
      });
      $(window).scroll(function () {
          if ($(this).scrollTop() > topHeight) {
              $('.header.is-clone').addClass('is-fixed');
          } else {
              $('.header.is-clone').removeClass('is-fixed');
              $('.header.is-clone').css({
                  "top": "-" + hAll + "px"
              });
          }
      });
  });

    /*----------------------------------------------------*/
    /*	Scroll Anchor
    /*----------------------------------------------------*/
    $(document).ready(function() {
        var maskClick = "#anchor-";//"#anchor-";
        $('[href*="' + maskClick + '"]').on('click', function() {
            var itemId = $(this).attr("href"),
            strName = itemId.replace('#',''),
            itemName = '[name=' + strName + ']';

            if ( $('*').is(itemId) || $('*').is(itemName) ) {
                var item = $('*').is(itemId) ? itemId : itemName,
                    itemTop = $(item).offset().top,
                    blockFixed = $(document).width() >= 768 ? ".header-clone" : ".wpar-fixed",
                    heightHeader = $(blockFixed).outerHeight(),
                    destination = itemTop - heightHeader;

                    console.log(itemTop);
                    console.log(heightHeader);
                    console.log(heightHeader);
                    console.log(blockFixed);
                
                $('.header-mobile--mobile').hide();
                $('.header-mobile__burger-menu').removeClass('active')

                $("html:not(:animated),body:not(:animated)").animate({scrollTop: destination}, 100);
            }
            return false;
        });
    });
  /* */

    function blockFixed(BF) {
        var $t = $(".block-fixed");
        var fixedHeight = $(".wpar-fixed").children(".block-fixed").outerHeight(),
            fixedTop = $t.offset().top,
            topScroll = fixedTop + fixedHeight;
            $(".wpar-fixed").height(fixedHeight);
            $(window).on('scroll resize', function () {
                if ($(this).scrollTop() > topScroll) {
                    onFixed($t);
                } else if ($(this).scrollTop() < 20) {
                    offFixed($t);
                }
            });
    }

    if ($('*').is('.block-fixed')) {
        $('.block-fixed').wrapAll('<div class="wpar-fixed"></div>');
        blockFixed();
        $(window).on('resize', blockFixed);
    }

  /*-----------------------Mobile menu slide--------------- */
        $('[data-control="menu-burger"]').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            var menu = $('.header-mobile--mobile');
            menu.slideToggle();
            $('.header-mobile').toggleClass('active');
        });
  /*--------------------------------------------------------*/

  /*-----------------------------script for hide product----------------------------------------*/
  $('.c-hidden__btn').on('click', function(e){
      e.preventDefault();
      $('.c-hidden').slideToggle();
      $('.c-hidden__btn').toggleClass('c-hidden__btn--active');
      // $('.c-hidden__btn').text('Скрыть');
  });
  /*--------------------------------------------------------------------------------------------*/

  /*----------------------Script for modal-------------------------------*/
      $(function () {
          var modal = $('.c-modal__container');
          var close = $('.c-form-message__item--close');

          close.on('click', function (e){
              e.preventDefault();
              modal.fadeOut(300);
          });
      });
  /*---------------------------------------------------------------------*/

  /*----------------------Script for btn up scroll-------------------------------*/

      $(function () {
          var scrollBtn = $('.btn-scroll');
          scrollBtn.on('click', function(e) {
              e.preventDefault();
              $("html:not(:animated),body:not(:animated)").animate({scrollTop: 0}, 800);
          });


      });

  /*---------------------------------------------------------------------*/

// ------Блок с выбором табов-----------------------------------
$(function(){
  var btn = $('.c-tab__link');
  var content = $('.c-tab__wrapper');
  btn.on('click', function(e){
      e.preventDefault();
      let _this = $(this);
      btn.removeClass('active');
      var count = $(this).data('slide');
      function showTab(content, dataTab){
          content.each(function(array, mas){
              $(this).css({opacity: 0.6}, 500);
              if(dataTab === array){
                _this.addClass('active');
                  $(this).animate({opacity: 1}, 1000);
                  $(this).css({display: 'block'});
              }else{
                  $(this).css({display: 'none'});
                  $(this).animate({opacity: 0.25}, 500);
              }
          });
      }
      showTab(content, count);
  });

});
// ---------------------------------------------------



/*--------------------Spoiler-------------------------------------------------*/

$(function () { 
  var accordeonTitle = $('.spoiler__title');
  var accordeonContent = $('.spoiler__content');
  accordeonTitle.on('click', function(e){
    e.preventDefault();
    $(this).next(accordeonContent).slideToggle();
    $(this).toggleClass('active');
 });
});

/*---------------------------------------------------------------------*/

$(function () { 
  var flag = true;
  $(window).scroll(function () {
    if (flag) {
        $('.count-span').each(function () {
            $(this).prop('Counter', 0).animate({
                Counter: $(this).text()
            }, {
            duration: 5000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    });
          flag = false;
      }
  });
});




})(jQuery);


document.addEventListener("DOMContentLoaded", function() {

    if (document.querySelector('.spoler')) {
        const spoler = document.querySelector('.spoler');
        spoler.addEventListener('click', () => {

            spoler.classList.toggle('active');

            const spolerArrow = spoler.querySelector('.spoler__icon');
            spolerArrow.classList.toggle('active');

            const specifications = document.querySelector('.table__specifications');
            specifications.classList.toggle('active');
        });

    }


    const advantagesSlider = new Swiper('.advantages__slider', {
        loop: true,
        pagination: {
          el: '.clever-pagination',
        },
    });

    const aboutextSlider = new Swiper('.about-text-slider', {
        loop: true,
        pagination: {
          el: '.clever-text-pagination',
        },
    });



    

    // const spoler = document.querySelector('.product__specifications');
    // const spolerText = document.querySelector('.product__specifications .product__title');
    
    // const spoler__title = document.createElement('div');
    // spoler__title.className = "spoler__title";
    // spoler__title.innerHTML = spolerText.text;

    // spoler.appendChild( spoler__title );

    // // let div = document.createElement('div');
    // // div.className = "spoler__arrow";
    // // div.innerHTML = "<svg width='90' height='90'><use xlink:href='img/sprite.svg#adv-1'></use></svg>";

    // // spoler.appendChild( div );  // добавляем наш элемент в элемент <body>





});


// var swiper = new Swiper(".mySwiper", {
//     loop: true,
//     spaceBetween: 10,
//     slidesPerView: 6,
//     freeMode: true,
//     watchSlidesVisibility: true,
//     watchSlidesProgress: true,
//     breakpoints: {
//         0:{
//             slidesPerView: 2,
//         },
//         640: {
//           slidesPerView: 2,
//         },
//         768: {
//           slidesPerView: 4,
//         },
//         1024: {
//           slidesPerView: 5,
//         },
//     },
// });
// var swiper2 = new Swiper(".mySwiper2", {
//     loop: true,
//     spaceBetween: 10,
//     navigation: {
//         nextEl: ".swiper-button-next",
//         prevEl: ".swiper-button-prev",
//     },
//     thumbs: {
//         swiper: swiper,
//     },
// });
